import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { motion, useScroll, useTransform } from 'framer-motion';
import throttle from 'lodash/throttle';
import './ScrollEffect.css';
import InfoBlocks from './InfoBlocks';
import Roadmap from './Roadmap';
import LoadingAnimation from './LoadingAnimation';
import ScrollHelper from './ScrollHelper';

const levelImages = Array.from({length: 10}, (_, i) => `/${i + 1}level.png`);
const desktopImages = ['dd.png', 'portal.png', 'giga.png', 'button.png'];
const mobileImages = ['giga.png'];


const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

const ScrollEffect = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrolled, setScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showScrollHelper, setShowScrollHelper] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = throttle(() => {
      const scrollPosition = window.scrollY;
      setScrolled(scrollPosition > 50);
      setShowScrollHelper(scrollPosition < 100);
    }, 200);

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    const isMobile = windowWidth < 1200;
    const imagesToLoad = isMobile 
      ? mobileImages 
      : [...levelImages, ...desktopImages];

    const totalImages = imagesToLoad.length;
    let loadedImages = 0;

    const loadImages = async () => {
      for (const src of imagesToLoad) {
        await preloadImage(src);
        loadedImages++;
        setLoadingProgress(Math.round((loadedImages / totalImages) * 100));
      }
      setTimeout(() => setIsLoading(false), 500);
    };

    loadImages();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [windowWidth]);

  const { scrollY } = useScroll();
  const scale = useTransform(
    scrollY,
    windowWidth < 700 ? [0, 1000] : [0, 2000],
    [1, 60]
  );
  const opacity = useTransform(
    scrollY,
    windowWidth < 700 ? [0, 500] : [0, 1000],
    [1, 0]
  );
  const additionalSectionOpacity = useTransform(
    scrollY,
    windowWidth < 700 ? [500, 600] : [800, 950],
    [0, 1]
  );
  const gigachadOpacity = useTransform(
    scrollY,
    [0, 350, 3000],
    [0, 1, 0]
  );

  if (isLoading) {
    return <LoadingAnimation progress={loadingProgress} />;
  }

  return (
    <div className="container">
      <ScrollHelper isVisible={showScrollHelper} />
      <motion.header
        className="site-header"
        initial={{ opacity: 1 }}
        animate={{ opacity: scrolled ? 0 : 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="header-buttons">
          <a href="https://x.com/gigachadguru" className="header-link">X</a>
          <a href="https://t.me/GigachadDigital" className="header-link" target="_blank" rel="noopener noreferrer">TELEGRAM</a>
        </div>
      </motion.header>
      {windowWidth >= 1200 && (
        <div>
          <motion.img
            src="dd.png"
            style={{ scale, opacity }}
            className={`background-image ${scrolled ? 'scrolled' : ''}`}
            alt="Dollars Background"
          />
          <div className="content">
            <motion.button
              className="airdrop-button"
              style={{ scale, opacity }}
              onClick={() => window.location.href="https://t.me/GigachadAppBot"}
            >
                <span className="button-text" style={{ opacity }}>GIGA GAME</span>
                <motion.div className="gigachad-dot" style={{ opacity: gigachadOpacity }}></motion.div>
            </motion.button>
          </div>
        </div>
      )}
      {windowWidth < 1200 && (
        <div>
          <div className="content">
            <motion.span className="gigabucks-airdrop" style={{ scale, opacity }}>
              <a href="https://t.me/GigachadAppBot" className="button-link">GIGA GAME</a>
            </motion.span>
          </div>
        </div>
      )}
      <motion.div className="additional-section" style={{ opacity: additionalSectionOpacity }}>
        <div className="key-features">
          <h2>KEY FEATURES</h2>
          <div className="key-feature-row">
            <div className="key-feature">
              <div className="key-feature-number">1</div>
              <div className="key-feature-content">
                <h3>Be strong</h3>
                <p>Hit the gym daily to build and strengthen your muscles.</p>
              </div>
            </div>
            <div className="key-feature">
              <div className="key-feature-number">2</div>
              <div className="key-feature-content">
                <h3>Be rich</h3>
                <p>Increase your wealth consistently every day.</p>
              </div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature">
              <div className="key-feature-number">3</div>
              <div className="key-feature-content">
                <h3>Get to work</h3>
                <p>Achieve success by working hard and staying dedicated.</p>
              </div>
            </div>
            <div className="key-feature">
              <div className="key-feature-number">4</div>
              <div className="key-feature-content">
                <h3>Rest</h3>
                <p>Restore your energy with proper rest to continue your journey of fitness and success.</p>
              </div>
            </div>
          </div>
          <div className="middle-button">
            <a href="https://t.me/GigachadAppBot">PLAY TO EARN</a>
          </div>
        </div>
        <div className="scroll_bg_wrapper">
          <div className="scroll_bg">
            <Marquee 
              speed={50}
              gradient={false}
              style={{
                '--duration': '30s',
                '--iteration-count': 'infinite',
                '--min-width': 'auto'
              }}>
              {levelImages.map((src, index) => (
                <div
                  key={index}
                  className="scroll-item"
                  style={{ 
                    backgroundImage: `url(${src})`,
                    marginRight: '10px'
                  }}
                />
              ))}
            </Marquee>
          </div>
        </div>
        <InfoBlocks />
        <Roadmap />
      </motion.div>
      <div className="footer-divider"></div>
      <footer className="site-footer">
        <div className="footer-pattern"></div>
        <div className="footer-links">
          <a href="https://x.com/gigachadguru">X</a>
          <a href="https://t.me/GigachadDigital">TELEGRAM</a>
        </div>
        <p>2024 GIGA</p>
      </footer>
    </div>
  );
};

export default ScrollEffect;