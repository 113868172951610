import React from 'react';
import './InfoBlocks.css';
import { FaDollarSign, FaGift, FaPuzzlePiece } from 'react-icons/fa';

const InfoBlocks = () => {
  const blocks = [
    {
      title: "PLAY TO EARN",
      icon: <FaDollarSign />,
      points: [
        "Develop your character’s body and wealth",
        "Earn points and complete tasks",
        "Level up your character to become GigaChad"
      ]
    },
    {
      title: "REAP THE REWARDS",
      icon: <FaGift />,
      points: [
        "Receive airdrop",
        "Grow your social network",
        "Watch your $GIGA tokens grow",
        "Enjoy continuous support and resources"
      ]
    },
    {
      title: "UTILITY MYSTERY",
      icon: <FaPuzzlePiece />,
      points: [
        "Don’t rush to spend your jettons for pizza",
        "Something great is coming"
      ]
    }
  ];

  return (
    <div className="info-blocks-container">
      <h2 className="info-blocks-heading">WHY OUR GAME?</h2>
      <div className="info-blocks">
        {blocks.map((block, index) => (
          <div className="info-block" key={index}>
            <div className="info-block-icon">
              {block.icon}
            </div>
            <h3 className="info-block-title">{block.title}</h3>
            <ul className="info-block-points">
              {block.points.map((point, idx) => (
                <li key={idx} className="info-block-point">{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoBlocks;
