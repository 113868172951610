import React, { useEffect } from 'react';
import './Roadmap.css';

const Roadmap = () => {
  useEffect(() => {
    const blocks = document.querySelectorAll('.roadmap-block');
    const handleScroll = () => {
      blocks.forEach((block, index) => {
        const rect = block.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          setTimeout(() => {
            block.classList.add('visible');
            if (index > 0) {
              document.querySelector(`.roadmap-line-${index}`).classList.add('visible');
            }
          }, index * 200);
        } else {
          block.classList.remove('visible');
          if (index > 0) {
            document.querySelector(`.roadmap-line-${index}`).classList.remove('visible');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="roadmap-container">
      <h2 className="roadmap-heading">ROADMAP</h2>
      <div className="roadmap">
        <div className="roadmap-block left">
          <h3>Phase 1</h3>
          <p>Beta test launch</p>
          <p>Game launch</p>
          <p>Consistent game improvements</p>
        </div>
        <div className="roadmap-line roadmap-line-1"></div>
        <div className="roadmap-block right">
          <h3>Phase 2</h3>
          <p>NFT collection</p>
          <p>Dex Listing</p>
          <p>Airdrop</p>
        </div>
        <div className="roadmap-line roadmap-line-2"></div>
        <div className="roadmap-block left">
          <h3>Phase 3</h3>
          <p>Special utility for big holders</p>
        </div>
        <div className="roadmap-line roadmap-line-3"></div>
        <div className="roadmap-block right">
          <h3>Phase 4</h3>
          <p>Mystery utility to be announced</p>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
