import React from 'react';
import { motion } from 'framer-motion';
import './ScrollHelper.css';

const ScrollHelper = ({ isVisible }) => {
  return (
    <motion.div 
      className="scroll-helper"
      initial={{ opacity: 0, y: -20 }}
      animate={{ 
        opacity: isVisible ? 1 : 0, 
        y: isVisible ? 0 : -20 
      }}
      transition={{ duration: 0.3 }}
    >
        <p>Scroll</p>
      <div className="scroll-arrow">&#8595;</div>
    </motion.div>
  );
};

export default ScrollHelper;